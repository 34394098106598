import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import index from '../views/report/profit.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/record/edit',
    name: 'record_add',
    component: () => import(/* webpackChunkName: "record" */ '../views/record/edit.vue')
  },
  {
    path: '/record/index',
    name: 'record_list',
    component: () => import(/* webpackChunkName: "record" */ '../views/record/index.vue')
  },
  {
    path: '/record/other_money',
    name: 'other_money',
    component: () => import(/* webpackChunkName: "record" */ '../views/record/other_money.vue')
  },

  {
    path: '/report/stock',
    name: 'report_stock',
    component: () => import(/* webpackChunkName: "record" */ '../views/report/stock.vue')
  },
  {
    path: '/report/profit',
    name: 'report_profit',
    component: () => import(/* webpackChunkName: "record" */ '../views/report/profit.vue')
  },
  {
    path: '/report/year',
    name: 'report_year',
    component: () => import(/* webpackChunkName: "record" */ '../views/report/year.vue')
  },

  {
    path: '/tool/plan',
    name: 'tool_plan',
    component: () => import(/* webpackChunkName: "record" */ '../views/tool/plan.vue')
  },

  {
    path: '/guest/index',
    name: 'guest_list',
    component: () => import(/* webpackChunkName: "record" */ '../views/guest/index.vue')
  },

  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "record" */ '../views/test.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
