let serverPath = "//kx.1314179.com.cn";
if (process.env.NODE_ENV === "development") {
    //serverPath = "//192.168.3.99";
}

const AppConfig = {
    apiUrl(url: string): string {
        return serverPath + url;
    },
    debug: false,
    kxApi: serverPath + "/api/kaixin.ashx",
    openApi: serverPath + "/api/open.ashx",
    feiheApi: serverPath + "/api/feihe.ashx",
    loginApi: serverPath + "/api/adminLogin.ashx",
    uploadApi: serverPath + "/service/admin.ashx?action=upload_img",
    SITE_CODE: "kaixin",
    recoveryApi: serverPath + "/api/recovery.ashx",
    imgServer: serverPath,
    imgFileType: "image/png,image/jpg,image/jpeg,image/gif",
    apiMode: "action",
    RecordTypeList: [
        {value: 1, label: '盘点'},
        {value: 2, label: '入库'},
        {value: 3, label: '出库'},
    ]
};


export {AppConfig};

export default AppConfig;
