const MenuPath = {
    record_list: '/record/index',
    record_add: '/record/edit',
    other_money: '/record/other_money',

    stock:'/report/stock',
    report_profit:'/report/profit',
    guest_list:'/guest/index',
    tool_plan:'/tool/plan'
}

export {
    MenuPath
}