class CountDown {
  _time: number;
  second: number;
  djs: any;

  constructor(_time = 60) {
    this._time = _time;
    this.second = 0;
  }

  /**
   * 启动倒计时
   */
  start() {
    this.second = this._time;
    this.djs = setInterval(() => {
      this.second--;
      if (this.second === 0) {
        clearInterval(this.djs);
        this.onEnd();
      }
    }, 1000);
  }

  /**
   * 倒计时结束时触发的回调
   */
  onEnd() {}

  clear() {
    clearInterval(this.djs);
  }
}

export { CountDown };

export default CountDown;
