/**
 * 获取星期几
 * @param date
 * @returns {string}
 */
function getWeekText(date: Date) {
  return ["周日", "周一", "周二", "周三", "周四", "周五", "周六"][
    date.getDay()
  ];
}

export { getWeekText };
