
import {defineComponent, ref, h} from "vue";
import {layer, common, Ajax} from "@/utils";
import LoginForm from "@/components/login-form.vue";
import {SyncOutlined} from '@ant-design/icons-vue';
import {MenuProps} from 'ant-design-vue';
import router from "@/router";
import {MenuPath} from "@/config/menuPath";
import {AppCache} from "@/model/appCache";

export default defineComponent({
  name: "App",
  components: {
    LoginForm,
  },

  setup() {
    const isLogin = ref(!!Ajax.header.token);
    if (!isLogin) {
      common.setTitle('管理员登录');
    }

    function onLoginSuccess() {
      isLogin.value = true;
    }

    const current = ref<string[]>(['convert']);
    const items = ref<MenuProps['items']>([{
        key: 'record_list',
        icon: () => h(SyncOutlined),
        label: '记录管理',
        children: [
          {
            label: '所有记录',
            key: 'record_list',
          },
          {
            label: '添加记录',
            key: 'record_add',
          },
          {
            label: '其他开支',
            key: 'other_money',
          }
        ],
      },
      {
        key: 'report',
        icon: () => h(SyncOutlined),
        label: '数据统计',
        children: [
          {
            label: '实时库存',
            key: 'stock',
          },
          {
            label: '每月利润',
            key: 'report_profit',
          },
          {
            label: '年度统计',
            key: 'report_year',
          }
        ],
      },
      {
        key: 'guest',
        icon: () => h(SyncOutlined),
        label: '客户管理',
        children: [
          {
            label: '客户管理',
            key: 'guest_list',
          }
        ],
      },
      {
        key: 'tool',
        icon: () => h(SyncOutlined),
        label: '工具',
        children: [
          {
            label: '报计划',
            key: 'tool_plan',
          }
        ],
      },
    ]);

    function handleClick(info: { key: string }) {
      if (!info.key) {
        return;
      }
      if(MenuPath.hasOwnProperty(info.key)){
        // @ts-ignore
        router.push(MenuPath[info.key]);
      }
    }
    AppCache.guestList.reload();
    AppCache.shoeTypeList.reload();

    return {
      isLogin,
      onLoginSuccess,
      current,
      items,
      handleClick
    }
  }
});
