import { Ajax } from "../ajax";
import { BaseModel } from "./base";
import common from "../common";
import { PageStore } from "@/utils/dataModel/pageStore";

/**
 * 数据实体基类 action模式
 */
class ActionDataModel extends BaseModel {
  /**
   * action模式下默认接口地址
   */
  Api: string;
  /**
   * action模式 获取分页列表的action
   */
  ListAction?: string;
  /**
   * action模式 获取完整列表的action
   */
  ListAllAction?: string;

  /**
   * action模式下 获取单条数据的action
   */
  GetAction?: string;
  /**
   * action模式下 保存单条数据的action
   */
  SetAction?: string;
  /**
   * action模式下 添加单条数据的action
   * 仅当添加与修改使用不同action时启用
   */
  AddAction?: string;
  /**
   * action模式下 删除数据的action
   * 仅当添加与修改使用不同action时启用
   */
  DeleteAction?: string;

  constructor() {
    super();
    this.Api = "";
    this.SetMode = "post";
    this.DelMode = "post";
  }

  Init() {
    this.Keys = Object.keys(this.Props);
    this.Restore();
    if (this.ListAction) {
      this.Store = new PageStore(this.Api, this.ListAction);
    }
    this.vInit();
  }

  CheckAction(
    actionName: "ListAllAction" | "GetAction" | "SetAction" | "DeleteAction"
  ) {
    if (!this.Api) {
      throw new Error(`${this.constructor.name}未定义Api`);
    }
    if (!this[actionName]) {
      throw new Error(`${this.constructor.name}未定义${actionName}`);
    }
  }

  /**
   * 获取所有数据
   * @param {object} opt - 可选，传递给接口的查询参数
   * @returns {Promise<T>}
   */
  GetAll(opt?: object) {
    this.CheckAction("ListAllAction");
    const data = {
      action: this.ListAllAction
    };
    if (opt && typeof opt === "object") {
      Object.assign(data, opt);
    }
    return Ajax.get(this.Api, data).then((result: any) => {
      this.vAfterGetList(result);
      this.List = result.data;
      return result.data;
    });
  }

  /**
   * 根据id获取单条记录
   * @param {number} id - 标识 可不传递
   * @returns {Promise<T>}
   */
  GetData(id: number | string | undefined) {
    this.CheckAction("GetAction");
    return Ajax.get(this.Api, {
      action: this.GetAction,
      [this.IdKey]: id || this.id
    }).then(res => {
      Object.assign(this, res.data);
      return this.vAfterGet(res);
    });
  }

  /**
   * 保存数据
   * @param {object} otherData -提交到后台的其他数据
   * otherData中不应使用action键名
   * @returns {*|Promise<AxiosResponse<any>>}
   */
  SetData(otherData?: object) {
    this.CheckAction("SetAction");
    const data: any = this.CreateFormData();
    if (otherData) {
      Object.assign(data, otherData);
    }

    if (this.id) {
      data.action = this.SetAction;
    } else {
      data.action = this.AddAction || this.SetAction;
    }
    return Ajax[this.SetMode](this.Api, data);
  }

  /**
   * 删除数据
   * @returns {*|Promise<AxiosResponse<any>>}
   */
  Delete(id?: number | string) {
    this.CheckAction("DeleteAction");
    return Ajax[this.DelMode](this.Api, {
      action: this.DeleteAction,
      [this.IdKey]: id || this.id
    });
  }

  /**
   * 批量删除数据
   * @returns {*|Promise<AxiosResponse<any>>}
   */
  DeleteMore(ids: string) {
    this.CheckAction("DeleteAction");
    return Ajax[this.DelMode](this.Api, {
      action: this.DeleteAction,
      ids
    });
  }
}

export default ActionDataModel;

export { ActionDataModel };
