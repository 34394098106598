<template>
  <div class="a-flex-space-between">
    <a-radio-group v-model:value="activeYear" button-style="solid" @change="changeYear">
      <a-radio-button :value="item" v-for="(item,index) in yearList" :key="index">{{ item }}</a-radio-button>
    </a-radio-group>
    <div class="p-total">
      累计销售利润：<span>{{sumSellProfit}}</span>，累计其他收支：<span>{{sumTotalOther}}</span>，累计净利润：<span>{{sumSum}}</span>
    </div>
  </div>
  <a-table :dataSource="result" :columns="columns" :pagination="false"
           class="a-mt-20">

  </a-table>
</template>


<script setup>
import {defineComponent, ref} from "vue";
import AppConfig from "@/app-config";
import {common, Ajax, arrTool} from "@/utils";

common.setTitle('每月利润');
const allData = ref([]);
const yearList = ref([])
const activeYear = ref(new Date().getFullYear());
const result = ref([]);
const sumSellProfit = ref(0);
const sumTotalOther = ref(0);
const sumSum = ref(0);

Ajax.get(AppConfig.feiheApi, {
  action: 'total_month_profit'
}).then(res => {
  res.data.forEach(function (item) {
    item.totalOther = 0;
    item.sum = item.sellProfit;
  })
  console.log('res.data', res.data);
  const data = res.data;

  Ajax.get(AppConfig.feiheApi, {
    action: 'total_month_other_money'
  }).then(res => {
    res.data.forEach(function (item) {
      const target = data.find(function (target) {
        return item.year === target.year && item.month === target.month;
      });
      if (target) {
        target.totalOther = item.totalOther;
        target.sum = target.totalOther + target.sellProfit;
      }
    });


    data.forEach(function (item) {
      item.sellProfit = item.sellProfit / 100;
      item.totalOther = item.totalOther / 100;
      item.sum = item.sum / 100;
    })
    allData.value = data;
    yearList.value = arrTool.selectPropUnique(data, 'year');
    changeYear(activeYear.value);
    console.log('data', data);

  })
});

function changeYear(newYear) {
  activeYear.value = newYear;
  result.value = allData.value.filter(function (item) {
    return item.year === activeYear.value;
  });
  sumSellProfit.value = arrTool.selectSum(result.value, 'sellProfit');
  sumTotalOther.value = arrTool.selectSum(result.value, 'totalOther');
  sumSum.value = arrTool.selectSum(result.value, 'sum');
}

const columns = [
  {
    title: '年', dataIndex: 'year', key: 'year', align: 'center'
  },
  {
    title: '月', dataIndex: 'month', key: 'month', align: 'center'
  },
  {
    title: '销售利润', dataIndex: 'sellProfit', key: 'sellProfit', align: 'center'
  },
  {
    title: '其他收支', dataIndex: 'totalOther', key: 'totalOther', align: 'center'
  },
  {
    title: '小计', dataIndex: 'sum', key: 'sum', align: 'center'
  }
];
</script>

<style scoped lang="less">
.p-total{
  span{
    color: red;
  }
}
</style>