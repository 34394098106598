import {DataCacheManage} from "@/utils/dataTool/dataCacheManage";
import {AppConfig} from "@/app-config";

const api = AppConfig.feiheApi;

const AppCache = {
    guestList: new DataCacheManage('guest_list', api, 'feihe_guest_list'),
    shoeTypeList: new DataCacheManage('productList', api, 'feihe_type_list_all'),
}

export {
    AppCache,
    api
}