import { Ajax, Cache } from "@/utils";

class DataCacheManage {
  Key: string;
  Api: string;
  Action: string;
  Data: any[];

  constructor(key: string, api: string, action: string) {
    this.Key = key;
    this.Api = api;
    this.Action = action;
    this.Data = [];

    if (Cache.has(key)) {//
      this.Data = Cache.getJson(this.Key) as any[];
    } else {
      this.reload();
    }
  }

  filter(res:any) {
    return res.data;
  }

  reload() {
    console.log('刷新缓存')
    return Ajax.get(this.Api, {
      action: this.Action
    }).then(res => {
      this.Data = this.filter(res);
      Cache.setJson(this.Key, this.Data);
      return this.Data;
    });
  }
}

export { DataCacheManage };

export default DataCacheManage;
